<template>
	<div
		class="flex-column justify-space-between items-center pb-10 in-out"
		style="position: fixed; width: 90px; bottom: 80px !important; right: 0px !important; z-index: 2"
	>
		<button
			v-if="false && is_top"
			@click="toTop"
			class="filter-icon"
		>
			<img :src="require('@/assets/image/icon_up.svg')"/>
		</button>

		<div
			v-if="is_join"
			class="cartel_join mt-10 pr-10"
		>
			<button
				@click="toJoin">
				<div class="cartel_join_btn">
					<span>{{ $language.common.cartel }}<br><em>{{ $language.common.join }}</em></span>

				</div>
			</button>
		</div>
		<div
			v-else-if="is_write"
			class="cartel_write mt-10"
		>
			<button
				@click="toWrite"
				class="filter-icon"
			>
				<img :src="require('@/assets/image/icon_write.svg')"/>
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CartelNavigate'
		, props: ['user', 'item_cartel']
		, data: function(){
			return {
				is_top: false
				, items: []
				, items_board_config: []
			}
		}
		, computed: {
			is_write: function () {
				let t = false
				if (this.item_cartel.cartl_entry_state_code == 'CA00300004' && this.$route.params.code != 'CA00700007') {
					t = true
				}

				return t
			}
			, is_join: function(){
				let t = false
				if(this.item_cartel.cartl_entry_state_code == ''){
					t = true
				}
				return t
			}
			, is_manager: function(){
				let t = false

				if(this.item_cartel.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500001'){
					t = true
				}
				return t
			}
			, list_board_config: function(){

				return this.items_board_config.filter( (item) => {

					if(this.is_manager){
						if(item.board_type_code != "CA00700006" && item.board_type_code != "CA00700007"){
							return item
						}
					}else{

						if(item.board_type_code == 'CA00700002' || item.board_type_code == 'CA00700003' || item.board_type_code == 'CA00700004'){
							if(item.board_authority.reg_fg == 'Y'){
								return item
							}
						}
					}
				})
			}
			, board_config: function(){
				let t = false
				this.items_board_config.filter( (item) => {
					if(item.board_type_code == this.$route.params.code){
						t = item
					}
				})

				return t
			}
		}
		, methods: {
			toTop: function(){
				document.body.scrollTo({ top: 0, behavior: "smooth"})
			}
			, toWrite: function(){
				let t = false
				let message = ''
				if(this.board_config){
					if(this.board_config.board_authority.reg_fg == 'Y'){
						t = true
					}else{
						message = '게시물 작성 권한이 없습니다.'
					}
				}else{
					if(this.list_board_config.length < 1){
						message = '작성가능한 게시판이 없습니다.'
					}else{
						t = true
					}
				}
				if(t){
					this.$bus.$emit('to', {name: 'mafia1271', params: {idx: this.$route.params.idx, code: this.$route.params.bulletin_number}})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: message})
				}
			}
			, toJoin: function(){
				if(this.user.member_number){
					this.$bus.$emit('to', { name: 'mafia050', params: { idx: this.$route.params.idx}} )
				}else{
					this.$bus.$emit('onLogin')
				}
			}
			, getData: async function(){
				try{
					let url = this.$api_url.api_path.get_open_cartel_menu2
					if(this.user.member_number){
						url = this.$api_url.api_path.get_cartel_menu2
					}
					const result = await this.$Request({
						method: 'post'
						,url: url
						,data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_type_code_list: [
								{
									board_type_code: 'CA00700002' // 한줄응원
									, inquiry_limit: 1
									, sort_condition: '1'
								}
								, {
									board_type_code: 'CA00700003' // 일반
									, inquiry_limit: 1
									, sort_condition: '1'
								}
								, {
									board_type_code: 'CA00700004' // 스토리
									, inquiry_limit: 1
									, sort_condition: '1'
								}
							]
						}
						, type: true
					})

					if(result.success){
						this.items_board_config = result.data.board_list
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			document.body.addEventListener('scroll', () => {
				let st = document.body.scrollTop

				if(st > 0){
					this.is_top = true
				}else{
					this.is_top = false
				}
			})
			if(this.$route.params.idx){
				this.getData()
			}
		}
	}
</script>

<style>
	.filter-icon { filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25)); border-radius: 100%; overflow: hidden }
</style>
<template>
	<div class="ct_nav_wrap">
		<!-- 메뉴영역 -->
		<div
			class="ct_nav flex-column justify-space-between"
		>
			<!-- 프로필 -->
			<div class="nav_profile">
				<!-- nft카드 닉네임 -->
				<div class="profile_login clear">
					<div class="profile_thumb">
						<img
							v-if="item_cartel.profle_nft_card_img_url"
							:src="$request.upload_url(item_cartel.profle_nft_card_img_url)" alt=""
						>
						<img
							v-else
							:src="require('@/assets/image/@noimage.png')" alt="noimage"
						>
					</div>
					<div
						v-if="user.member_number"
						class="profile_info"
					>
						<strong class="profile_nickname">{{ item_cartel.nickname }}</strong>
						<p class="login_status"> {{ item_cartel.cartl_member_grade_name }} <button @click="is_on_grade = true" style="border: 1px solid #ddd; border-radius: 100%; display: inline-block; padding: 1px 5px; font-size: 8px">?</button></p>
					</div>
					<div
						v-else
						class="profile_info"
					>
						<div><button class="btn_s btn_fill_blue" @click="$emit('cancel'); $bus.$emit('onLogin')">{{ $language.common.login}}</button></div>
						<p class="login_status mt-5"> 등급 안내 <button @click="is_on_grade = true" style="border: 1px solid #ddd; border-radius: 100%; display: inline-block; padding: 1px 5px; font-size: 8px">?</button></p>
					</div>
				</div>
				<!-- 설정버튼 -->
				<div
					v-if="is_setting"
					class="profile_set"
				>
					<button
						@click="toSetting"
						class="btn_setup"
					>
						<span class="hide">{{ $language.common.setting }}</span>
					</button>
				</div>
				<!-- 설정버튼 -->
				<hr class="hr_solid"><!-- 구분선 -->
			</div>
			<!-- 프로필 -->
			<div class="scroll_h">
				<!-- 전체글보기 -->
				<div class="menu_first">
					<a
						@click="toAll"
						class="all"
					>{{ $language.notice.all_article_view }}</a>
				</div>
				<!-- 전체글보기 -->

				<template
					v-if="items_voting.length > 0"
				>
					<hr class="hr_dashed"><!-- 구분선 -->

					<!-- 투표 게시판 -->
					<div class="menu_area">
						<ul class="menu_text_area overflow-y-auto">
							<li
								v-for="(item, v_index) in items_voting"
								:key="'vote_' + v_index"
							>
								<button
									@click="toVotingList(item)"
									class="tit_text"
								>{{ item.board_name }}</button>

							</li>
						</ul>
					</div>
				</template>

				<template
					v-if="list_shorts.length > 0 || list_tv.length > 0"
				>
					<hr class="hr_dashed"><!-- 구분선 -->

					<!-- 숏플 게시판 -->
					<div class="menu_area">
						<ul class="menu_text_area overflow-y-auto">
							<li
								v-for="(shorts, s_index) in list_shorts"
								:key="'shorts_' + s_index"
								@click="toShort(shorts)"
							>
								<button
									class="tit_text"
								><img :src="require('@/assets/image/icon_shorts.png')" /> {{ $language.shorts.title_shorts}} <!-- 숏플 --> </button>
							</li>

							<li
								v-for="(tv, t_index) in list_tv"
								:key="'tv_' + t_index"
								@click="toTv(tv)"
							>
								<button
									class="tit_text"
								><img :src="require('@/assets/image/icon_shorts.png')" /> {{ $language.tv.title_tv}} <!-- 마피아 TV --> </button>
							</li>
						</ul>
					</div>
				</template>

				<hr class="hr_dashed"><!-- 구분선 -->
				<!-- 기본메뉴 -->
				<div class="menu_area">
					<h3 class="hide">{{ $language.notice.each_notice_view }}</h3>
					<ul class="menu_text_area overflow-y-auto mb-150">
						<li
							v-for="(item, index) in fav_notice"
							:key="'fav1_' + index"
						>
							<button
								@click="to(item)"
								class="tit_text"
							>{{ item.board_name }}</button>

							<template
								v-if="user.member_number"
							>
								<button
									v-if="item.board_type_code == 'CA00700004' || item.board_type_code == 'CA00700005'"
									aria-label="active" class="btn_favorites" :class="{ on: item.fav }"
									@click="postFav(item, index)"
								></button>
								<label
									v-else
									aria-label="active" class="btn_favorites on"
								><input type="checkbox" style="visibility: hidden" /></label>
							</template>

						</li>
						<li
							v-for="(item, index) in fav_list"
							:key="'fav2_' + index"
						>
							<button
								@click="to(item)"
								class="tit_text"
							>{{ item.board_name }}</button>

							<button
								v-if="user.member_number"
								aria-label="active" class="btn_favorites" :class="{ on: item.fav }"
								@click="postFav(item, index)"
							></button>
						</li>
					</ul>
					<div
						class="mt-auto position-absolute" style="bottom: 0; left: 0"
						v-if="false && ($route.params.idx == '400894257692612143f5' || $route.params.idx == '373257550565555f12d1')"
						@click="toEvent"
					>
						<div class="pa-10">
						<img :src="require('@/assets/image/event/banner_onus_20230830.png')" class="width-100"/>
						</div>
					</div>
				</div>
				<!-- //전체글보기 -->
			</div>


			<div
				v-if="false && live_info.chg_cartl_member_div_code === 'CA02500001'"
				class="mt-auto btn_area pa-20"
			>
				<button
					@click="toLive"
					class="btn_l btn_fill_red"
                    :disabled="cartel.cartl_chttg_room_list[0].cartl_chttg_room_number !== ''"
				><v-icon class="color-white">mdi mdi-play-box-outline</v-icon> {{ $language.live.live_request }} <!-- 라이브 채팅 개설 --></button>
			</div>
		</div>
		<!-- //메뉴영역 -->
		<!-- 백그라운드 -->
		<div
			@click="$emit('cancel')"
			class="bg_overlay" aria-label="background"
		></div>
		<!-- //백그라운드 -->

		<cartel_grade
			v-if="is_on_grade"
			:user="user"

			@cancel="is_on_grade = false"
			@click="is_on_grade = false"
		></cartel_grade>
	</div>
</template>

<script>
	import cartel_grade from "@/view/Cartel/cartel_grade";
	export default{
		name: 'mafia055'
		, components: {cartel_grade}
		, props: ['user', 'cartel', 'live_info']
		, data: function(){
			return {
				fav: []
				, base_list: [
					{ code: 'notice', name: this.$language.common.notice , fav: 0, sort: 1}
					, { code: 'base1', name: this.$language.common.oneLineCheering, fav: 0, sort: 2}
				]
				, item_cartel: this.cartel
				, items_board_config: []
				, main_nft: {}
				, is_on_grade: false
				, items_voting: []
				, items_shorts: []
			}
		}
		, computed: {
			fav_list: function(){

				return this.items_board_config.filter((item) => {

					if(item.board_bookmark_fg == 'Y' ){
						item.fav = true
					}else{
						item.fav = false
					}
					if(item.board_type_code == 'CA00700003'){
						return item
					}
				})
			}
			, list_shorts: function(){
				return this.items_shorts.filter((item) => {
					if(item.board_type_code == 'CA00700006'){
						return item
					}
				})
			}
			, list_tv: function(){
				return this.items_board_config.filter((item) => {
					if(this.$is_on_tv && item.board_type_code == 'CA00700007' ){
						return item
					}
				})
			}
			, fav_notice: function(){
				return this.items_board_config.filter((item) => {

					if(item.board_bookmark_fg == 'Y' ){
						item.fav = true
					}else{
						item.fav = false
					}
					if(item.board_type_code != 'CA00700003' && item.board_type_code != 'CA00700006' && item.board_type_code != 'CA00700007'){
						return item
					}
				})
			}
			, is_setting: function(){
				let t = false

				if(this.cartel.cartl_entry_state_code == 'CA00300004'){
					t = true
				}

				return t
			}
		}
		, methods: {
			postFav: async function(bbs){
				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_fav
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: bbs.board_number
							, board_bookmark_fg: !bbs.fav ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getCartelMenu()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getBoardList: async function(){
				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_board_config_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_board_config = result.data.board_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,getMainNft: async function(){

				try{
					const result = await this.$Request({
						method: 'get'
						,url: this.$api_url.api_path.get_main_nft
						,data: {
							type: 'my_card'
							, key: 'is_main'
							, val: true
						}
					})

					if(result.success){
						this.main_nft = result.data
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, to: function(item){
				if(item.board_authority.inquiry_fg != 'Y'){
					this.$bus.$emit('notify', { type: 'error', message: this.$language.auth.no_authority_lookup})
				}else{
					if(item.board_type_code === 'CA00700001'){
						this.$bus.$emit('to', { name: 'mafia127', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, from: this.$route.name }, not_query: true})
					}else{
						this.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, from: this.$route.name }, not_query: true})
					}
				}
			}
			, getCartelMenu: async function(){
				try{
					let url = this.$api_url.api_path.get_open_cartel_menu
					if(this.user.member_number){
						url = this.$api_url.api_path.get_cartel_menu
					}
					const result = await this.$Request({
						method: 'post'
						,url: url
						,data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.items_board_config = result.data.board_list

						this.$bus.$emit('setBoardConfigSort', this.items_board_config)
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, getCartelMenu2: async function(){
				try{
					let url = this.$api_url.api_path.get_open_cartel_menu2
					if(this.user.member_number){
						url = this.$api_url.api_path.get_cartel_menu2
					}
					const result = await this.$Request({
						method: 'post'
						,url: url
						,data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_type_code_list: [
								{
									board_type_code: 'CA00700005'
									, inquiry_limit: 1
									, sort_condition: '1'
								}
								, {
									board_type_code: 'CA00700006'
									, inquiry_limit: 1
									, sort_condition: '1'
								}
							]
						}
						, type: true
					})

					if(result.success){
						this.items_shorts = result.data.board_list
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toSetting: function(){
				this.$bus.$emit('to', { name: 'mafia074', params: { idx: this.$route.params.idx, from: 'mafia049' }})
			}
			, toAll: function(){
				this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx }})
				this.$emit('cancel')
			}
			, toLive: function() {
				// console.log(this.item_cartel)
				this.$bus.$emit('to', {name: 'CartelLiveRequest', params: {idx: this.$route.params.idx}})
			}
			, toEvent: function(){
				this.$bus.$emit('to', { name: 'EventDetail', params: { idx: 4 }})
			}
			, getVoting: async function(){
				try{

					let url = this.$api_url.api_path.get_open_vote_list
					if(this.user.member_number){
						url = this.$api_url.api_path.get_voting_board_list
					}
					const result = await this.$Request({
						method: 'post'
						,url: url
						,data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, vote_release_settup_fg: ''
						}
						, type: true
					})

					if(result.success){
						this.items_voting = result.data.cartl_vote_board_list
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toVotingList: function(item){

				// 사용자 목록
				let d = { name: 'CartelVotingList', params: { idx: this.$route.params.idx, v_id: item.board_number, type: 'ing', from: this.$route.name }}

				// 관리 목록
				if(this.cartel.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500001' || (item.admin_authority_settup_fg == 'Y' && this.cartel.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500002')){
					d = { name: 'CartelVotingList', params: { idx: this.$route.params.idx, v_id: item.board_number, type: 'ing', from: this.$route.name }}
				}
				this.$bus.$emit('to', d)
			}
			, toShort: function(item){
				this.$bus.$emit('to', { name: 'CartelShorts', params: { idx: this.$route.params.idx, b_id: item.board_number + ''}})
			}
			, toTv: function(tv){

				this.$bus.$emit('to', { name: 'MafiaTvList', params: {idx: this.$route.params.idx, code: tv.board_type_code, b_id: tv.board_number, from: this.$route.name}})

				// this.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: tv.board_type_code, b_id: tv.board_number, from: this.$route.name }, not_query: true})
			}
		}
		,async created() {
			await this.getCartelMenu()
			await this.getCartelMenu2()
			await this.getVoting();
			//await this.getBoardList()
		}
	}
</script>
import { render, staticRenderFns } from "./cartel_grade.vue?vue&type=template&id=494cfd46&"
import script from "./cartel_grade.vue?vue&type=script&lang=js&"
export * from "./cartel_grade.vue?vue&type=script&lang=js&"
import style0 from "./cartel_grade.vue?vue&type=style&index=0&id=494cfd46&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
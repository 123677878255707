<template>
	<PopupLayer
		@click="$emit('cancel')"
		@cancel="$emit('cancel')"

		style="z-index: 999999; font-size: 14px"
	>
		<template
			v-slot:body
		>
			<div class="bg-white  radius-20 overflow-hidden">
				<div class="pa-20">
					<h3 class="pop_tit pop_tit_logo text-center under-line mt-30 pb-30">{{ $language.cartel.notice_grade_info}}</h3>
					<ul class="top-line mt-10 overflow-y-auto  mb-10" style="max-height: 320px">
						<template
							v-for="(item, index) in items"
						>
							<li

								:key="'item_' + index"
								class="under-line pb-10 mt-10"
							>
								<h4>{{ item.cartl_member_grade_name}}</h4>
								<div
									v-if="item.cartl_member_div_code == 'CA02500003'"
									class="mt-10"
								>NFT 보유수량 {{ item.nft_holding_quantity }}개, 점수 {{ item.score | makeComma}}점</div>
								<div
									v-else-if="item.cartl_member_div_code == 'CA02500002'"
								>카르텔 운영자</div>
								<div
									v-else-if="item.cartl_member_div_code == 'CA02500001'"
								>카르텔 개설자</div>
							</li>
						</template>
					</ul>
					<div class="mt-10">{{ $language.cartel.notice_grade_info_txt }}</div>
				</div>
				<div
					class="btn_wrap"
				>
					<button
						@click="$emit('click')"
						class="pop_btn btn_fill_blue"
					>{{  $language.common.ok }}</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>

	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'cartel_grade'
		, components: {PopupLayer}
		, props: ['user']
		, data: function(){
			return {
				items: []
			}
		}
		, methods: {
			getData: async function(){
				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_open_cartel_member_grade_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.items = result.data.cartl_member_grade_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.getData()
		}
	}
</script>

<style>
	.bg-white { background-color: white;}
</style>
<template>
	<div
		ref="post_list_item_view"
	>
		<div class="header">
			<div
				class="top top_mafi "
			><!--trans-->
				<div class="util shadow">
					<button
						@click="toCartel"
						class="arrow_white"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit ">{{ item_board_config.board_name }}</h2>
				<div class="util shadow">
					<a
						@click="toSearch"
						class="img_button2 "
					><span class="hide">{{ $language.common.search }}</span></a>
					<button
						v-if="is_view_side"
						@click="is_side = !is_side"
						class="ico_ham ml-10"
					><span class="hide">{{ $language.common.menu }}</span></button>
				</div>
			</div>
		</div>

		<div
			class="post_wrap pb-100" :class="{ 'mt-50': $route.params.code}"
		>
			<div class="container">
				<CartelBbsList
					:user="user"
					:items_list="items_cartel_bbs"
					:max="max"
					:is_add="is_add"
					:item_search="item_search"
					:item_cartel="item_cartel"
					:from="'list'"

					@click="update"
				></CartelBbsList>
			</div>
		</div>

		<CartelNaviage
			:user="user"
			:item_cartel="item_cartel"
		></CartelNaviage>

		<mafia055
			v-if="is_side"
			:user="user"
			:cartel="item_cartel"
			:live_info="cartl_member_grade_chg_info"

			@to="to"
			@cancel="is_side = false"
		></mafia055>

		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_profile"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<mafia064
			v-if="is_more"

			:user="user"
			:item_bbs="item_more"
			@cancel="is_more = false"
			@deleteBbs="deleteBbs"
			@move="moveBbs"
			@modify="onModify"
			@block="doBlock"
			style="z-index: 1003"
		>
		</mafia064>

		<popup_confirm
			v-if="is_delete"

			@click="deleteBbs"
			@cancel="is_delete = false"
		>
			<template
				v-slot:title
			>{{ $language.notice.article_delete }}</template>
			<template
				v-slot:main-txt
			>{{ $language.notice.article_delete_message }}</template>
			<template
				v-slot:sub-txt
			>{{ $language.notice.article_delete_message_confirm }}</template>
		</popup_confirm>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getCartelBbs"
		></ScrollPagination>

		<mafia1272
			v-if="is_item_modify"

			:user="user"
			:cartel="item_cartel"
			:bbs="item_more"

			@cancel="is_item_modify = false"
			@click="modify"
			class="layer-popup"
		></mafia1272>
	</div>
</template>


<script>

import mafia055 from '@/view/Cartel/mafia055'
import mafia064 from "@/view/Cartel/mafia064";
import popup_confirm from "@/view/Layout/PopupConfirm";
import mafia098 from '@/view/Cartel/mafia098'
import ScrollPagination from "@/components/ScrollPagination";
import Mafia1272 from "@/view/CartelBoard/mafia127-2";
import CartelNaviage from "@/view/Cartel/CartelNavigate";
import CartelBbsList from "@/view/Cartel/CartelBbsList";

export default {
	name: 'mafia049'
	, props: ['user']
	, components: {
		CartelBbsList,
		CartelNaviage,
		Mafia1272,
		mafia064, mafia055, popup_confirm, mafia098, ScrollPagination}
	, data: function(){
		return {
			program: {
				name: 'cartel'
				, title: this.$language.common.cartel
				, type: 'cartel'
				, not_header: true
			}
			, item_cartel: {}
			, items_cartel: []
			, items_cartel_notice: []
			, items_cartel_bbs: []
			, idx_cartel: this.$route.params.idx
			, is_side: false
			, item_my_cartel: {}
			, is_more: false
			, item_more: {
				post_file_list: []
			}
			, is_trans: true
			, item_board_config: {}
			, is_delete: false
			, is_098: false
			, icons_emoji: [

        { code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
        , { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
        , { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
        , { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
        , { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
        , { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
			]
			, item_search: {
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			}
			, type: 'list'
			, is_add: true
			, max: false
			, is_item_detail: false
			, is_item_detail_comment: false
			, item_detail: {

			}
			, is_item_modify: false
			, item_profile: {}
			, cartl_member_grade_chg_info: []
		}
	}
	, computed: {
		is_write: function(){
			let t = false
			if(this.item_board_config.board_authority){

				if(this.item_board_config.board_authority.reg_fg == 'Y'){
					t = true
				}
			}
			return t
		}
		, list_bbs: function(){
			let self = this
			return this.items_cartel_bbs.filter(function(item){
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}
				if(item.sympaty_icon_code != ''){
					item.is_empathy = true
				}
				item.time_t = self.$date.getTimeStory(item.registdate)
				return item
			})
		}
		, is_join: function(){
			let t = false
			if(this.item_cartel.cartl_entry_state_code == ''){
				t = true
			}
			return t
		}
		, is_join_confirm: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code) {
				case 'CA00300004':
					t = true
					break
			}
			return t
		}
		, is_join_cancel: function (){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300003':
				case 'CA00300005':
				case 'CA00300006':
				case 'CA00300007':
					t = true
					break;
			}
			return t
		}
		, is_join_wait: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300001':
				case 'CA00300002':
					t = true
					break;
			}
			return t
		}
		, is_view_side: function(){
			let t = true
			if(this.$route.params.code == 'CA00700007'){
				t = false
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.get_open_cartel_info
				if(this.user.member_number){
					url = this.$api_url.api_path.get_cartel_info
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
						, page_number: 1
						, pagerecnum: 10
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.cartl_member_grade_chg_info = result.data.cartl_member_grade_chg_info

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartelCheer: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.get_open_cartel_cheer_list
				if(this.user.member_number){
					url = this.$api_url.api_path.get_cartel_cheer_list
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){

					if(this.item_search.page_number == 1){
						this.items_cartel_bbs = result.data.post_list
					}else{
						this.items_cartel_bbs = this.items_cartel_bbs.concat(result.data.post_list)
					}

					if(result.data.post_list.length > 0){
						this.item_search.page_number++
					}else{
						this.removeScroll()
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelNotice: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.get_open_cartel_notice_list
				if(this.user.member_number){
					url = this.$api_url.api_path.get_cartel_notice_list
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						//, board_number: this.$route.params.b_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(this.item_search.page_number == 1) {
						this.items_cartel = result.data.post_list
					}else{
						this.items_cartel = this.items_cartel_bbs.concat(result.data.post_list)
					}

					if(result.data.post_list.length > 0){
						this.item_search.page_number++
					}else{
						this.removeScroll()
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelBbs: async function(page = 1){
			try{
				//this.$bus.$emit('on', true)
				this.$set(this.item_search, 'page_number', page)
				let url = this.$api_url.api_path.get_open_board_bbs_list
				if(this.user.member_number){
					url = this.$api_url.api_path.get_board_bbs_list
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(result.data.post_list.length > 0){
						this.items_cartel_bbs = this.items_cartel_bbs.concat(result.data.post_list)
						this.max = false
					}else{
						this.max = true
					}
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_more = false
			}
		}

		, onMore: function(item, index){
			this.is_more = true
			this.item_more = item
			this.item_more.index = index
		}
		, onDelete: function(){
			this.is_more = false
			this.is_delete = true
		}
		, handleClick(event, ref) {
			console.log(this.$refs, event, ref)
			this.$refs[ref][event]();
		}
		, getBoardConfig: async function() {
			try {
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.get_open_board
				if(this.user.member_number){
					url = this.$api_url.api_path.get_board_config
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})

				if (result.success) {
					this.item_board_config = result.data

					await this.getBbsList()
				} else {
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', {type: 'error', message: result.result_msg})
					}
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, deleteBbs: async function(){
			this.is_item_detail = false
			this.is_more = false
			this.items_cartel_bbs.splice(this.item_more.index, 1)
		}
		, moveBbs: async function(){
			this.is_more = false
			await this.mount()
		}

		, getBbsList: async function(){

			this.is_add = false

			if(this.$route.params.code == 'CA00700001'){
				await this.getCartelNotice()
			}else if(this.$route.params.code == 'CA00700002') {
				await this.getCartelCheer()
			}else if(this.$route.params.code){
				await this.getCartelBbs()
			}

			let self = this
			setTimeout(() => {
				self.is_add = true
			}, 500)
		}
		, mount: async function(){
			this.$emit('onLoad', this.program)

			this.is_side = false
			this.items_cartel_bbs = []
			this.item_search = {
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			}
			document.body.scrollTop = 0

			await this.getCartel()
			await this.getBoardConfig()

		}

		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.is_emoji = false
					item.sympaty_count++
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}
		, toCartel: function(){
			this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx }})
		}
		, toSearch: function(){
			this.$bus.$emit('to', { name: 'mafia0531', params: this.$route.params})
		}
		, toDetail: function(item){

			if(!item.is_block) {
				this.item_more = item

				this.is_item_detail = true
				this.is_item_detail_comment = false

				let path = this.$route.path.split('#')

				if (path[1] == 'detail') {
					this.$router.push(path[0])
				} else {
					this.$router.push(this.$route.path + '#detail')
				}
			}

			//this.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, toComment: function(item){

			if(this.user.member_number){

				this.item_more = item

				// this.is_item_detail = true
				// this.is_item_detail_comment = true

				this.$router.push(this.$route.path + '#comment')

				//this.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number}})
			}else{
				this.$bus.$emit('onLogin')
			}
		}
		, to: function(params){
			params.type = 'href'
			this.$emit('to', params)
			this.is_side = false
		}
		, onModify: function(){
			this.is_item_modify = true
		}
		, modify: function(item){
			this.is_more = false
			this.is_item_modify = false
			console.log('modify', item)
			this.getBbs(item)
			if(this.is_item_detail){
				this.is_item_detail = false
				setTimeout(() => {
					this.is_item_detail = true
				}, 5)
			}
		}

		, getBbs: function(item){
			switch (item.board_type){
				case 'CA00700001':
					this.getCartelNoticeInfo(item)
					break
				case 'CA00700002':
					this.getCartelCheerInfo(item)
					break
				case 'CA00700005':
					this.getCartelSubscribeInfo(item)
					break
				default:
					this.getCartelBbsInfo(item)
					break
			}
		}
		, getCartelSubscribeInfo: async function(item){
			try{
				if(!item.bulletin_number){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_board_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : item.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : item.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					let bbs = result.data

					this.item_more.contents = bbs.post_contents

					/*
					if(bbs.post_file_list.length > 0){
						for(let i = 0; i < bbs.post_file_list.length; i++){
							this.item_more.post_file_list[i].post_appendix_file_div_code = bbs.post_file_list[i].post_appendix_file_div_code
							this.item_more.post_file_list[i].file_url = bbs.post_file_list[i].post_file_url
						}
					}

					 */

					this.item_more.comment_count = bbs.comment_tot_count
					this.item_more.sympaty_count = bbs.sympaty_list.length

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelBbsInfo: async function(item){
			try{
				if(!item.bulletin_number){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					let bbs = result.data

					this.item_more.contents = bbs.post_contents

					/*
					if(bbs.post_file_list.length > 0){
						for(let i = 0; i < bbs.post_file_list.length; i++){
							console.log(this.item_more)
							this.item_more.post_file_list[i].appendix_file_div_code = bbs.post_file_list[i].post_appendix_file_div_code
							this.item_more.post_file_list[i].file_url = bbs.post_file_list[i].post_file_url
						}
					}
					*/

					this.item_more.comment_count = bbs.comment_tot_count
					this.item_more.sympaty_count = bbs.sympaty_list.length

					this.$set(this.items_cartel_bbs, this.item_more.index, this.item_more)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelNoticeInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data

					this.is_vote = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.item_bbs.title = this.item_bbs.post_title
					this.item_bbs.is_comment = this.item_bbs.comment_writing_fg == 'Y' ? true : false

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							console.log(key)
							this.files.push({
								name: val.post_file_name
								, src: val.post_file_url
								, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
							})
							this.upload_files.push(val)
						}
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelCheerInfo: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: item.cartl_number
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_bbs[this.item_more.index] = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, update: function(item){

			console.log('update', item.index, item)
			this.$set(this.items_cartel_bbs, item.index, item)
		}
		, on098: function(item){
			this.is_098 = true
			this.item_profile = item
		}
		, doBlock: function(type){

			this.items_cartel_bbs = this.items_cartel_bbs.filter((item) => {
				if(item.post_member_number == this.item_more.post_member_number){
					item.blok_member_fg = type ? 'Y' : 'N'
				}
				return item
			})
		}
	}
	,async  created() {
		await this.mount()
	}
	, watch:{
		$route(to, from){
			console.log(to, from)
			this.is_item_detail = false
			this.is_item_detail_comment = false
			if(to.hash){
				switch (to.hash){
					case '#detail':
						this.is_item_detail = true
						break
					case '#comment':
						this.is_item_detail = true
						this.is_item_detail_comment = true
						break
				}
			}
		}
	}
}
</script>

<style>
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }
.bg-gray { background-color: #eee}
</style>